import React from 'react';
// import { useIntl } from "gatsby-plugin-intl";
// import { useTranslations } from 'gatsby-plugin-translate';

// Components
import {
  Layout,
  Container,
  HomeHero,
  Statement,
  HomeContent,
  ServiceCard,
  ServiceGroup,
  ServiceAddonCard,
  HomeHeaderBg,
  HomeFooterBg,
  Carousel,
  Testimonial,
} from '../components';

// Data
import data from '../data/pages/home.json';
import services from '../data/services.json';
import testimonials from '../data/testimonials.json';

const Home = () => {
  // const t = useTranslations(); //useIntl().formatMessage;
  const content = data.content;

  return (
    <>
      <Layout pageContext={data} useDarkHeader={false} isDarkTheme noHero>
        {/* Custom background overlay */}
        <HomeHeaderBg />

        {/* Custom home page hero */}
        <HomeHero
          title={content.hero.title}
          text={content.hero.text}
          cta={content.hero.cta}
        />

        {/* Statement */}
        <Container>
          <Statement
            title={content.statement.title}
            styleType="dark"
            removeMobileStyles
            gutter={{ top: '4rem', bottom: '4rem' }}>
            <p>{content.statement.text}</p>
          </Statement>
        </Container>

        {/* Full Statement */}
        <Container>
          <HomeContent
            imageList={content.fullStatement.imageList}
            blocks={content.fullStatement.statements}
            reversed
          />
        </Container>

        {/* Service List */}
        <Container>
          <ServiceGroup rows={2}>
            <ServiceAddonCard
              reversed
              title={content.services.entry.title}
              text={content.services.entry.text}
              cta={content.services.entry.cta}
            />
            {content.services.items.map((slug) => {
              const service = services.data[slug];
              return (
                <ServiceCard
                  reversed
                  key={service.path}
                  title={service.title}
                  text={service.blurb}
                  imagePath={service.imagePath}
                  cta={{ src: service.path, label: 'Learn More' }}
                />
              );
            })}
            <ServiceAddonCard
              reversed
              title={content.services.exit.title}
              links={content.services.exit.links}
              cta={content.services.exit.cta}
            />
          </ServiceGroup>
        </Container>

        {/* Testimonials */}
        <Container gutter={{ top: '10rem' }}>
          <Carousel
            title="We Build Lasting Trusted Relationships with our Clients"
            subtitle="What our clients say"
            reversed>
            {testimonials.data.map((testimonial, index) => (
              <div key={index}>
                <Testimonial
                  key={index}
                  text={testimonial.text}
                  author={testimonial.author}
                  reversed
                />
              </div>
            ))}
          </Carousel>
        </Container>

        {/* Custom background overlay */}
        <HomeFooterBg />
      </Layout>
    </>
  );
};

export default Home;
